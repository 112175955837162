import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { trackEvent } from 'utils/Mixpanel';
import { fetchProductGroups } from 'redux/productGroups';
import {
  Page,
  IndexTable,
  LegacyCard,
  EmptyState,
  LegacyStack,
  Thumbnail,
  Badge,
  Button,
  Modal,
  TextContainer,
  Pagination,
  Text,
} from "@shopify/polaris";
import UpgradeTrigger from 'components/UpgradeTrigger';
import StarRating from 'components/StarRating';
import roundRating from 'utils/roundRating';

import family from 'assets/images/family.svg';
import bundle from 'assets/images/bundle.svg';
import groupingSvg from 'assets/images/grouping.svg';

function Groups({ productGroups, fetchProductGroups }) {
  const [showNewModal, setShowNewModal] = useState(false);

  useEffect(() => {
    try {
      window.localStorage.setItem('product_groups_visited', true);
    } catch (e) {
      // ignore
    }
  }, []);

  useEffect(() => {
    fetchProductGroups({});
  }, [fetchProductGroups]);

  const groupTypes = [
    {
      style: 'family',
      label: 'Family',
      description: 'Reviews will be merged across all members. Best for products that are similar enough to share reviews (ie. different colours).'
    },
    {
      style: 'bundle',
      label: 'Bundle',
      description: 'Share reviews from relevant products on a bundle’s review display. Best for bundles that are sold as standalone products.'
    }
  ];

  return <>
    <Page
      title="Groups"
      primaryAction={{
        content: 'Create Group',
        onAction: () => setShowNewModal(true),
      }}
    >
      <LegacyCard>
        <UpgradeTrigger
          feature={'pf_product_aliasing'}
          title={'Upgrade to use product groups'}
          description={'You need to upgrade your plan to use product groups'}
        >
          <IndexTable
            resourceName={{ singular: 'group', plural: 'groups' }}
            itemCount={productGroups?.data?.length || 0}
            headings={[
              { title: 'Group' },
              { title: 'Type' },
              { title: 'Products' },
              { title: 'Rating' },
              { title: 'Action', hidden: true }
            ]}
            selectable={false}
            hasMoreItems={false}
            emptyState={
              <EmptyState
                heading="Create groups to organize review displays"
                action={{ content: 'Create group', onAction: () => setShowNewModal(true) }}
                image={groupingSvg}
              >
                <p>Use groups to share reviews across similar products, add products to bundles, and create custom displays.</p>
              </EmptyState>
            }
            loading={productGroups?.loading || false}
          >
            {productGroups?.data?.map((group, index) => (
              <IndexTable.Row
                id={group.id}
                key={group.id}
                position={index}
                disabled={!!group.deleted_at}
                tone={!!group.deleted_at ? "subdued" : "default"}
              >
                <IndexTable.Cell>
                  <LegacyStack alignment="center">
                    <Thumbnail
                      source={group.style === 'family' ? family : bundle}
                      size="small"
                    />
                    <p>{group.name}</p>
                    {!!group.deleted_at && <Text as="p" tone="critical">Deleting...</Text>}
                  </LegacyStack>
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Badge tone={group.style === 'family' ? 'default' : 'info'}>
                    <span style={{ textTransform: 'capitalize' }}>{group.style}</span>
                  </Badge>
                </IndexTable.Cell>
                <IndexTable.Cell>{group.product_count}</IndexTable.Cell>
                <IndexTable.Cell>
                  {group.rating_average ? (
                    <LegacyStack alignment="center" spacing="tight">
                      <p>{roundRating(group.rating_average)}</p>
                      <StarRating rating={group.rating_average} />
                    </LegacyStack>
                  ) : (
                    <Text variant="bodyMd" as="span" tone="subdued">No ratings</Text>
                  )}
                </IndexTable.Cell>
                <IndexTable.Cell>
                  <Button
                    url={`/products/groups/${group.id}`}
                  >
                    {!!group.deleted_at ? "View" : "Edit"}
                  </Button>
                </IndexTable.Cell>
              </IndexTable.Row>
            ))}
          </IndexTable>
          {productGroups?.data?.length > 0 && (
            <LegacyCard.Section>
              <Pagination
                hasPrevious={productGroups?.meta?.page?.before || false}
                onPrevious={() => {
                  fetchProductGroups({
                    page: { before: productGroups?.meta?.page?.before }
                  });
                }}
                hasNext={productGroups?.meta?.page?.after || false}
                onNext={() => {
                  fetchProductGroups({
                    page: { after: productGroups?.meta?.page?.after }
                  });
                }}
              />
            </LegacyCard.Section>
          )}
        </UpgradeTrigger>
      </LegacyCard>
    </Page>
    <Modal
      open={showNewModal}
      onClose={() => setShowNewModal(false)}
      title="Select group type"
      secondaryActions={[{
        content: 'Cancel',
        onAction: () => setShowNewModal(false),
      }]}
    >
      <UpgradeTrigger
        feature={'pf_product_aliasing'}
        title={'Upgrade to use product groups'}
        description={'You need to upgrade your plan to use product groups'}
      >
        {groupTypes.map((groupType) => (
          <Modal.Section key={groupType.style}>
            <TextContainer>
              <Text variant="headingMd" as="h2">{groupType.label}</Text>
              <p>{groupType.description}</p>
              <Button

                url={`/products/groups/create?style=${groupType.style}`}
                onClick={() => {
                  trackEvent(`Product Groups - Create ${groupType.label} Group`);
                }}
                variant="primary">
                Select
              </Button>
            </TextContainer>
          </Modal.Section>
        ))}
      </UpgradeTrigger>
    </Modal>
  </>;
}

const mapStateToProps = (state) => ({
  productGroups: state.productGroups
});

const mapDispatchToProps = (dispatch) => ({
  fetchProductGroups: ({ include, page }) => dispatch(fetchProductGroups({ include, page })),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Groups);
